import { FC, useState } from 'react';
import { Burger, FSOverlay } from '~/components';
import { Frontmatter } from '~/config';

// eslint-disable-next-line
const frontmatter: Frontmatter = {
  title: 'Library: Burger Icon',
  orderInLib: 10,
  screenShoot: false,
};

const LibraryPage: FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <FSOverlay center>
      <Burger open={isOpen} onClick={() => setIsOpen(!isOpen)} />
    </FSOverlay>
  );
};

export default LibraryPage;
